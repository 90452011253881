const version = '0.0.5';

import { cleanupOutdatedCaches, precacheAndRoute } from 'workbox-precaching';
import { registerRoute } from 'workbox-routing';
import { NetworkFirst, CacheFirst } from 'workbox-strategies';
import { setCacheNameDetails } from 'workbox-core';
import { getDb, storeResults} from './idb-utils';
import axios from 'axios';

setCacheNameDetails({
    prefix: 'northstandard-pwa',
    suffix: 'v1',
    precache: 'precache',
    runtime: 'runtime',
});

cleanupOutdatedCaches();

// Precache files
precacheAndRoute(self.__WB_MANIFEST || []);

console.log('Service Worker: Precache and route setup completed');

// Cache API responses
registerRoute(
    ({ url }) => url.origin === 'https://QI33H8CI46-dsn.algolia.net' && url.pathname.startsWith('/1/indexes/dev_vessels'),
    new NetworkFirst({
        cacheName: 'algolia-vessel-api-cache',
        plugins: [
            {
                cacheWillUpdate: async ({ response }) => {
                    if (response && response.status === 200) {
                        return response;
                    }
                    return null;
                },
            },
        ],
    })
);

console.log('Service Worker: API cache route setup completed');

// Cache static resources (CSS, JS)
registerRoute(
    ({ request }) => request.destination === 'style' || request.destination === 'script',
    new CacheFirst({
        cacheName: 'static-resources',
    })
);

console.log('Service Worker: Static resources cache route setup completed');

// Cache HTML pages (NetworkFirst strategy)
registerRoute(
    ({ request }) => request.mode === 'navigate',
    new NetworkFirst({
        cacheName: 'html-cache',
        plugins: [
            {
                cacheWillUpdate: async ({ response }) => {
                    if (response && response.status === 200) {
                        return response;
                    }
                    return null;
                },
            },
        ],
    })
);

console.log('Service Worker: HTML cache route setup completed');

// Cache image assets
registerRoute(
    ({ request }) => request.destination === 'image',
    new CacheFirst({
        cacheName: 'image-cache',
        plugins: [
            {
                cacheWillUpdate: async ({ response }) => {
                    if (response && response.status === 200) {
                        return response;
                    }
                    return null;
                },
            },
        ],
    })
);

console.log('Service Worker: Image cache route setup completed');

// Cache FontAwesome icons/SVGs
registerRoute(
    ({ url }) => url.origin === 'https://kit.fontawesome.com' && url.pathname.includes('/36c898f8c3.js'),
    new CacheFirst({
        cacheName: 'font-awesome-cache',
        plugins: [
            {
                cacheWillUpdate: async ({ response }) => {
                    if (response && response.status === 200) {
                        return response;
                    }
                    return null;
                },
            },
        ],
    })
);

console.log('Service Worker: FontAwesome cache route setup completed');

// Cache Google Fonts
registerRoute(
    ({ url }) => url.origin === 'https://fonts.googleapis.com' || url.origin === 'https://fonts.gstatic.com',
    new CacheFirst({
        cacheName: 'google-fonts-cache',
        plugins: [
            {
                cacheWillUpdate: async ({ response }) => {
                    if (response && response.status === 200) {
                        return response;
                    }
                    return null;
                },
            },
        ],
    })
);

console.log('Service Worker: Google Fonts cache route setup completed');


self.addEventListener('fetch', (event) => {
    if (event.request.url.includes('/search')) {
        console.log('Service Worker: Handling search request');
        event.respondWith(handleSearchRequest(event.request));
    } else {
        event.respondWith(fetch(event.request).catch(() => caches.match(event.request)));
    }
});

self.addEventListener('message', (event) => {
    if (event.data && event.data.action === 'sync-algolia') {
        syncAlgoliaData();
    }
});

self.addEventListener('message', (event) => {
    if (event.data && event.data.action === 'new-version') {
        self.skipWaiting();
        clearAllCaches();
    }
});

async function clearAllCaches() {
    const cacheNames = await caches.keys();
    await Promise.all(
        cacheNames.map(cacheName => caches.delete(cacheName))
    );
    console.log('Service Worker: All caches cleared');
    // Optionally re-populate the cache with critical resources

    // const cache = await caches.open('static-resources');
    // await cache.addAll([
    //     '/',
    //     '/styles/main.css',
    //     '/script/main.js'
    //     // Add other critical resources you want to cache initially
    // ]);
    // console.log('Service Worker: Re-populated cache with critical resources');
}

async function handleSearchRequest(request) {
    try {
        const url = new URL(request.url);
        const searchTerm = url.searchParams.get('query') || '';

        if (navigator.onLine) {
            console.log('Service Worker: Online, fetching request from network');
            return fetch(request);
        } else {
            console.log('Service Worker: Offline, fetching request from IndexedDB with searchTerm:', searchTerm);
            const db = await getDb();
            const tx = db.transaction('vessels', 'readonly');
            const store = tx.objectStore('vessels');
            const allVessels = await store.getAll();
            console.log('Service Worker: Retrieved vessels from IndexedDB:', allVessels);

            const results = allVessels.filter(vessel =>
                vessel.name && vessel.name.toLowerCase().includes(searchTerm.toLowerCase())
            );

            console.log('Service Worker: Filtered results:', results);

            return new Response(JSON.stringify({ hits: results }), {
                headers: { 'Content-Type': 'application/json' }
            });
        }
    } catch (error) {
        console.error('Service Worker: Error in handleSearchRequest:', error);
        return new Response(JSON.stringify({ error: 'Failed to fetch data' }), {
            headers: { 'Content-Type': 'application/json' },
            status: 500
        });
    }
}


async function syncAlgoliaData() {
    console.log('Service Worker: Starting syncAlgoliaData...');
    try {
        const db = await getDb();

        const vesselStoreEmpty = await isStoreEmpty(db, 'vessels');
        const correspondentStoreEmpty = await isStoreEmpty(db, 'correspondents');
        const peopleStoreEmpty = await isStoreEmpty(db, 'people');
        const contactsListsStoreEmpty = await isStoreEmpty(db, 'contacts-lists');
        const favouritesStoreEmpty = await isStoreEmpty(db, 'favourites');
        const savedPortsStoreEmpty = await isStoreEmpty(db, 'saved-ports');

        if (!(vesselStoreEmpty && correspondentStoreEmpty && peopleStoreEmpty && contactsListsStoreEmpty && favouritesStoreEmpty && savedPortsStoreEmpty)) {
            console.log('Service Worker: Stores are not empty, no need to sync.');
            return;
        }

        // const cache = await caches.open('sync-cache');
        // const lastSyncResponse = await cache.match('last-sync');
        // const lastSync = lastSyncResponse ? parseInt(await lastSyncResponse.text(), 10) : null;

        // use localstorage for cached timestamps.
        const lastSync = localStorage.getItem('last-sync');

        const now = Date.now();
        const oneDayInMs = 24 * 60 * 60 * 1000;

        if (lastSync && (now - lastSync) < oneDayInMs) {
            console.log('Service Worker: Sync already performed within the last day, skipping sync.');
            return;
        }

        await Promise.all([
            fetchAndStoreIndexData('vessels', 'https://QI33H8CI46-dsn.algolia.net/1/indexes/dev_vessels/query'),
            fetchAndStoreIndexData('correspondents', 'https://QI33H8CI46-dsn.algolia.net/1/indexes/dev_correspondents/query'),
            fetchAndStoreIndexData('people', 'https://QI33H8CI46-dsn.algolia.net/1/indexes/dev_people_listing_title_asc/query'),
            fetchAndStoreContactsLists(),
            fetchAndStoreFavourites(),
            fetchAndStorePorts()
        ]);

        console.log('Service Worker: Data synchronisation completed');

        // use localstorage for cached timestamps.
        localStorage.setItem('last-sync', now.toString());
        // await cache.put('last-sync', new Response(now.toString()));
    } catch (error) {
        console.error('Service Worker: Error syncing Algolia data:', error);
    }
}

async function isStoreEmpty(db, storeName) {
    const tx = db.transaction(storeName, 'readonly');
    const store = tx.objectStore(storeName);
    const count = await store.count();
    return count === 0;
}

async function fetchAndStoreIndexData(storeName, url) {
    let page = 0;
    let totalFetched = 0;
    const hitsPerPage = 1000;

    while (true) {
        console.log(`Service Worker: Fetching page ${page} for store '${storeName}'`);
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'X-Algolia-API-Key': '8f30cac1e37ce61c0f30027ba78ad5c1',
                'X-Algolia-Application-Id': 'QI33H8CI46',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ params: `hitsPerPage=${hitsPerPage}&page=${page}` })
        });

        const data = await response.json();
        const hits = data.hits;
        totalFetched += hits.length;

        console.log(`Service Worker: Fetched ${hits.length} hits from Algolia for store '${storeName}', page ${page}`);

        await storeResults(storeName, hits);

        if (hits.length < hitsPerPage) {
            //console.log(`Service Worker: Fetched less than ${hitsPerPage} hits, exiting loop for store '${storeName}'`);
            break; // Exit the loop if less than hitsPerPage hits are returned (end of data)
        }

        page++;
    }

    console.log(`Service Worker: All ${totalFetched} hits stored in IndexedDB for store '${storeName}'`);
}

async function fetchAndStoreContactsLists() {
    console.log(`Service Worker: Fetching Contacts Lists`);

    let hits = [];

    axios.get('/sanctum/csrf-cookie').then(() => {
        return axios.get('/api/contacts-lists').then(response => {
            hits = response.data;
            console.log(hits);
        }).catch((e) => {
            console.log('error fetching list, perhaps the user is unauthenticated');
        });
    });

    await storeResults('contacts-lists', hits);

    console.log(`Service Worker: All ${hits.length} hits stored in IndexedDB for store 'contacts-lists'`);
}

async function fetchAndStoreFavourites() {
    console.log(`Service Worker: Fetching Favourites`);

    let hits = [];

    axios.get('/sanctum/csrf-cookie').then(() => {
        return axios.get('/api/favourite-contacts').then(response => {
            hits = response.data;
            console.log(hits);
        }).catch((e) => {
            console.log('error fetching list, perhaps the user is unauthenticated');
        });
    });

    await storeResults('favourites', hits);

    console.log(`Service Worker: All ${hits.length} hits stored in IndexedDB for store 'favourites'`);
}

async function fetchAndStorePorts() {
    console.log(`Service Worker: Fetching Ports`);

    let hits = [];

    axios.get('/sanctum/csrf-cookie').then(() => {
        return axios.get('/api/correspondent-list').then(response => {
            const correspondentList = response.data;
            const ports = {};
            correspondentList.forEach(item => {
                const country = item.port_country;
                if (!ports[country]) {
                    ports[country] = [];
                }
                ports[country].push({
                    id: `${country}-${item.port_name}`, // Ensure unique ID
                    name: item.port_name
                });
            });
            for (const country in ports) {
                hits.push(...ports[country]);
            }
        }).catch((e) => {
            console.log('error fetching ports, perhaps the user is unauthenticated');
        });
    });

    await storeResults('saved-ports', hits);

    console.log(`Service Worker: All ${hits.length} hits stored in IndexedDB for store 'savedPorts'`);
}

export {
    fetchAndStoreContactsLists,
    fetchAndStoreFavourites,
}
